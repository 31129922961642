import { createClient } from "./client";
import type { AuthError, OAuthResponse } from "@supabase/supabase-js";

/**
 * Sign in with Google (Gmail)
 * Primary authentication method for the application
 * @param redirectTo - The URL to redirect to after authentication
 */
export async function signInWithGoogle(redirectTo?: string): Promise<OAuthResponse> {
  const supabase = createClient();
  return supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo: redirectTo || `${window.location.origin}/auth/callback?provider=google`,
      scopes: "email profile",
      queryParams: {
        prompt: "select_account",
        access_type: "offline",
      },
    },
  });
}

