"use client";

import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Mail } from "lucide-react";
import { signInWithGoogle } from "@/auth/utils/social-login";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { useAuth } from "@/contexts/auth-context";
import { toast } from "sonner";
import { FullPageLoading } from "@/components/ui/loading";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertTriangle } from "lucide-react";

const images = [
  {
    src: "https://images.unsplash.com/photo-1521702813222-1943f3fb9c07?auto=format&fit=crop&q=100&w=1200",
    alt: "Person working on laptop in a modern office setting",
  },
  {
    src: "https://images.unsplash.com/photo-1664575599618-8f6bd76fc670?auto=format&fit=crop&q=100&w=1200",
    alt: "Modern workspace with laptop",
  },
  {
    src: "https://images.unsplash.com/photo-1664575600796-ffa828c5cb6e?auto=format&fit=crop&q=100&w=1200",
    alt: "Creative office environment",
  },
];

export default function LandingPage() {
  const router = useRouter();
  const { user, isLoading } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [supabaseConfigured, setSupabaseConfigured] = useState(true);

  // Check if Supabase is configured
  useEffect(() => {
    const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
    if (!supabaseUrl || supabaseUrl === "https://your-project-id.supabase.co") {
      setSupabaseConfigured(false);
    }
  }, []);

  // Redirect to chat if already authenticated
  useEffect(() => {
    if (user && !isLoading) {
      router.push("/chat");
    }
  }, [user, isLoading, router]);

  // Image carousel effect
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const getImageIndex = (offset: number) => {
    return (currentIndex + offset + images.length) % images.length;
  };

  const handleGoogleLogin = async () => {
    try {
      setIsAuthenticating(true);
      toast.loading("Signing in with Gmail...");
      const { error } = await signInWithGoogle();
      if (error) {
        toast.dismiss();
        toast.error(`Sign in failed: ${error.message}`);
        setIsAuthenticating(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("An unexpected error occurred");
      console.error("Error during Google login:", error);
      setIsAuthenticating(false);
    }
  };

  // Show loading state
  if (isLoading) {
    return <FullPageLoading />;
  }

  // Skip rendering if already authenticated
  if (user) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {!supabaseConfigured && (
        <Alert variant="destructive" className="m-4">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Configuration Error</AlertTitle>
          <AlertDescription>
            Supabase URL is not properly configured. Please set
            NEXT_PUBLIC_SUPABASE_URL in your .env.local file.
          </AlertDescription>
        </Alert>
      )}

      <main className="flex-1">
        <section className="w-full py-6 sm:py-12 md:py-24 lg:py-32 xl:py-48">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[400px_1fr] lg:gap-12 xl:grid-cols-[600px_1fr]">
              <div className="flex flex-col justify-center space-y-4 pl-4">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                    Lema: AI Social Media Assistant
                  </h1>
                  <p className="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400">
                    Get an AI companion to increase your social media performance
                    here.
                  </p>
                </div>
                <div className="flex flex-col gap-3 min-[400px]:flex-row pl-4">
                  <Button
                    onClick={handleGoogleLogin}
                    className="bg-white text-black border border-gray-300 hover:bg-gray-50 w-full"
                    disabled={isAuthenticating}
                  >
                    <Mail className="mr-2 h-4 w-4" /> Sign in with Gmail
                  </Button>
                </div>
                <p className="text-sm text-gray-500 mt-4 pl-4">
                  By signing up, you agree to our{" "}
                  <Link href="/terms" className="underline">
                    terms of service
                  </Link>{" "}
                  and{" "}
                  <Link href="/privacy" className="underline">
                    privacy policy
                  </Link>
                  .
                </p>
              </div>

              <div className="relative h-[550px] w-full flex items-center justify-center">
                {/* Background images */}
                <motion.div
                  className="absolute left-0 z-0"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 0.5, scale: 0.85 }}
                  transition={{ duration: 0.5 }}
                >
                  <Image
                    src={images[getImageIndex(-1)].src}
                    alt={images[getImageIndex(-1)].alt}
                    width={300}
                    height={300}
                    className="rounded-xl object-cover opacity-30"
                  />
                </motion.div>
                <motion.div
                  className="absolute right-0 z-0"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 0.5, scale: 0.85 }}
                  transition={{ duration: 0.5 }}
                >
                  <Image
                    src={images[getImageIndex(1)].src}
                    alt={images[getImageIndex(1)].alt}
                    width={300}
                    height={300}
                    className="rounded-xl object-cover opacity-30"
                  />
                </motion.div>

                {/* Main image */}
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentIndex}
                    className="absolute z-10"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Image
                      src={images[currentIndex].src}
                      alt={images[currentIndex].alt}
                      width={600}
                      height={600}
                      className="rounded-xl object-cover shadow-2xl"
                      priority
                      quality={100}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
