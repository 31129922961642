import { Loader2 } from "lucide-react";

interface LoadingProps {
  size?: number;
  className?: string;
  text?: string;
}

export function Loading({ size = 24, className = "", text }: LoadingProps) {
  return (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <Loader2 className="animate-spin" size={size} />
      {text && <p className="mt-2 text-sm text-gray-500">{text}</p>}
    </div>
  );
}

export function FullPageLoading() {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Loading size={32} text="Loading..." />
    </div>
  );
}
